import React from "react";

import "./footer.css";

const Footer = () => {
  return (
    <footer className="footer__container">
      <div className="footer__subcontainer">
        <div className="footer__walmartlogo">
          <svg viewBox="0 0 72.69 81.59" alt="Walmart">
            <path
              d="M36.34 27.47c1.91 0 3.48-1 3.69-2.28l1.89-21.35c0-2.11-2.47-3.84-5.58-3.84s-5.57 1.73-5.57 3.84l1.88 21.35c.21 1.29 1.79 2.28 3.69 2.28zM24.8 34.14c1-1.66.88-3.52-.13-4.34L7.13 17.49c-1.82-1-4.56.22-6.11 2.91s-1.29 5.69.54 6.75l19.42 9c1.22.45 2.87-.41 3.82-2.06zm23.08-.01c1 1.65 2.61 2.51 3.82 2.06l19.43-9c1.83-1.06 2.09-4.06.55-6.75s-4.3-4-6.12-2.91L48 29.8c-1 .82-1.08 2.68-.12 4.34zm-11.54 20c1.91 0 3.48 1 3.69 2.27l1.89 21.35c0 2.11-2.47 3.84-5.58 3.84s-5.57-1.73-5.57-3.84l1.88-21.35c.21-1.28 1.79-2.27 3.69-2.27zm11.54-6.67c1-1.65 2.61-2.51 3.82-2.05l19.43 9c1.83 1.06 2.09 4.07.55 6.76s-4.3 4-6.12 2.9L48 51.81c-1-.84-1.08-2.7-.12-4.35zm-23.08 0c1 1.65.88 3.51-.13 4.35L7.13 64.1c-1.82 1.06-4.56-.22-6.13-2.9s-1.29-5.7.54-6.76l19.42-9c1.22-.46 2.87.4 3.83 2.05z"
              data-name="Layer 1"
              fill="#fdbb30"
            ></path>
          </svg>
        </div>
        <div className="footer__text">
          <div className="footer__text__reference">
            <div>© {new Date().getFullYear()} Walmart</div>
            <a
              href="https://www.walmart.com/cp/baby-gift-ideas/8636404"
              target="_blank"
              rel="noreferrer"
            >
              Walmart Baby Gift Ideas &nbsp;
              <svg
                x="0"
                y="0"
                viewBox="0 0 490 490"
                fill="white"
                width="0.6em"
                height="0.6em"
              >
                <path d="M15.541,490V0l458.917,245.009L15.541,490z"></path>
              </svg>
            </a>
          </div>
          <div className="footer__legaltext">
            <a
              href="https://corporate.walmart.com/privacy-security/walmart-privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            <a
              href="https://corporate.walmart.com/terms-of-use"
              target="_blank"
              rel="noreferrer"
            >
              Terms of Use
            </a>
            <a
              href="https://corporate.walmart.com/privacy-security/california-privacy-rights"
              target="_blank"
              rel="noreferrer"
            >
              CA Privacy Rights
            </a>
            <a
              href="https://www.walmart.com/account/api/ccpa-intake?native=false&amp;app=gm&amp;type=sod"
              target="_blank"
              rel="noreferrer"
            >
              Do Not Sell My Personal Information
            </a>
            <a
              href="https://www.walmart.com/account/api/ccpa-intake?native=false&amp;app=gm&amp;type=access"
              target="_blank"
              rel="noreferrer"
            >
              Request My Personal Information
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
