import React from "react";
import ShopNowAdImage from "../shop-now-ad-image";
import "./shop-now-ad.css";
import { openUrl } from "../../../lib/utils";

const WALMART_BABY_GIFT_URL = "https://www.walmart.com/cp/baby-gift-ideas/8636404";

const ShopNowAd = ({ ekoId }) => {

  return (
    <div className="shop-now-ad--container">
      <div className="shop-now-ad--image">
        <div className="shop-now-ad--image-aspect-ratio">
          <ShopNowAdImage imagePath="giftfinder-minisite/shop-now-ad.png" />
        </div>
      </div>
      <div className="shop-now-ad--cta-container">
        <div className="shop-now-ad--text">
          Find more gifts from Walmart for all the little ones in your life.
        </div>
        <div className="shop-now-ad--cta">
          <a
            href='#'
            target="_blank"
            rel="noreferrer"
            className="shop-now-ad--cta-link"
            onClick={function() {
                openUrl(WALMART_BABY_GIFT_URL, { ekoId, appId: 'giftfinder-minisite-app' })
            }}
          >
            Shop now
          </a>
        </div>
      </div>
    </div>
  );
};

export default ShopNowAd;
