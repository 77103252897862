/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import Thumbnail from "../../../images/giftfinder-minisite/thumbnail.png";
import Header from "../header";
import Footer from "../footer";
import SEO from "../seo";
import "./layout.css";

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          giftFinder {
            title
          }
        }
      }
    }
  `);

  return (
    <div className="layout__container">
      <SEO image={Thumbnail} />
      <Header siteTitle={data.site.siteMetadata.giftFinder?.title || `Title`} />
      <div className="layout__wrapper">
        <main>{children}</main>
      </div>
      <Footer />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
