import PropTypes from "prop-types";
import React from "react";

import BackgroundImg from "../../../images/giftfinder-minisite/header-background.png";
import HeaderLogoSrc from "../../../images/giftfinder-minisite/spark.svg";

import "./header.css";

const Header = ({ siteTitle }) => (
  <header
    className="header__backgroundimg"
    style={{
      backgroundImage: `url(${BackgroundImg})`,
    }}
  >
    <div className="header__img__wrapper">
      <img className="header__img" src={HeaderLogoSrc} alt="Walmart" />
    </div>
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
