import track from 'we-sdk/utils/track';

const walmartURLRegex = /^(.+\.)?walmart\.com$/;

export function isInWalmartCategoryPage() {
    return typeof window !== 'undefined' &&
        /[\?&]wcp=/.test(window.location.search);
}

export function randomStr() {
    return Math.random().toString(36).substring(7);
}

export function getCategoryPageId() {
    return isInWalmartCategoryPage() &&
        window.location.search.match(/[\?&]wcp=(.*?)(&.*)?$/)[1];
}

export function isSafariBrowser() {
    return /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
}

export function isIOSBrowser() {
    return (
		[
			'iPad Simulator',
			'iPhone Simulator',
			'iPod Simulator',
			'iPad',
			'iPhone',
			'iPod',
		].includes(navigator.platform) ||
		// iPad on iOS 13 detection
		(navigator.userAgent.includes('Mac') && 'ontouchend' in document)
	);
}

/**
 * Check if the giving url is a walmart url
 * @param url
 * @returns {boolean}
 */
function isWalmartUrl(url) {
    let urlObject;
    try {
        urlObject = new URL(url);
    } catch (e) {
        console.log(e);
    }
    return !!urlObject && walmartURLRegex.test(urlObject.hostname);
}

/**
 * Set a query param named by 'key' with the given value for a giving URL and replaces it exists.
 * Uses the URL object and for browsers that do not support it, implementation is based on the <a> tag
 * @param url
 * @param key
 * @param value
 * @returns {string} Return a new URL with the giving query param being set
 */
function setUrlParam(url, key, value) {
    if (!url || !key || !value) {
        return url;
    }

    try {
        let urlObject = new URL(url);
        urlObject.searchParams.set(key, value);
        url = urlObject.href;
    } catch (e) {
        // Cases where URL constructor is not supported on older browsers
        var a = document.createElement('a');
        a.href = url;
        if (a.search) {
            // Match the key as query param with som value in first group (matched lazily to stop at next occurance
            // of &), and stops at & or end of string.
            var regex = new RegExp("(" + key + "=.*?)(&.*|$)");
            if (a.search.match(regex)) {
                a.search = a.search.replace(regex, key + "=" + value + "$2");
            } else {
                a.search += "&" + key + "=" + value;
            }

        } else {
            a.search = key + "=" + value;
        }
        url = a.href;
    }

    return url;
}

/**
 * Open a URL.
 * If embedded in a Walmart Category Page, will notify parent Walmart frame that a URL needs to be opened.
 * Otherwise, if not embedded in WCP, will open a URL in a new tab.
 * The function will add the following query params to any walmart url:
 * sharedid=<pageviewid>
 * subid1=<app_id>
 * subid2=<projectid>
 * subid3=<projectname>
 * @export
 * @param {string} url - URL to open.
 * @param {object} [options] - Optional options object.
 * @param {string} [options.id] - Optional id to be reported to parent WCP frame.
 * @param {string} [options.ekoId] - Optional ekoId (projectId) that was being played.
 * @param {string} [options.appId] - Optional the app id
 * @param {string} [options.projectName] - Optional the project name
 * @param {string} [options.walmartId] - Optional the walmart product sku id
 */
export function openUrl(url, options = {}) {
    const { id = 'unknown', ekoId, appId, projectName, walmartId } = options;

    if (isWalmartUrl(url)) {
        // Pass/override sharedid url param with EkoAnalytics.pageViewId param
        if (window.EkoAnalytics && window.EkoAnalytics('getPageViewId')) {
            url = setUrlParam(url, 'sharedid', window.EkoAnalytics('getPageViewId'));
        }
        url = setUrlParam(url, 'subid1', appId);
        url = setUrlParam(url, 'subid2', ekoId);
        url = setUrlParam(url, 'subid3', projectName);

        track('walmart.linkout', {
            url,
            appId,
            projectId: ekoId,
            walmartId
        });
    }

    track('url.open', {
        url,
        id,
    });

    if (isInWalmartCategoryPage()) {
        // Notify parent (Walmart Category Page) that we want to open a URL
        window.parent.postMessage({
            type: 'url.open',
            params: {
                url,
                id: `eko-wcp-${id}-btn`,
            },

            origin: 'eko',
            embedapi: '1.0',
            wcp: getCategoryPageId()
        }, '*');
    } else {
        // Otherwise, just open URL in new tab
        window.open(url, '_self');
    }
}
