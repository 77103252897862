import React, { useState, useEffect } from "react";
import { isIOSBrowser, isSafariBrowser } from "../lib/utils";

import Layout from "../components/giftfinder-minisite/layout";
import EkoPlayer from "../components/giftfinder-minisite/eko-player";
import MainText from "../components/giftfinder-minisite/main-text";
import RotateLogo from "../images/giftfinder-minisite/rotate.png";
import EkoLogo from "../images/giftfinder-minisite/powered-by-eko.png";
import ShopNowAd from "../components/giftfinder-minisite/shop-now-ad";

const PRODUCT_FINDER_PROJECT_ID = 'V1W65G';

const EKO_PLAYER_URL =
  `/embed/${PRODUCT_FINDER_PROJECT_ID}/?headnodeid=node_1_0_start_screen_1f2e10&embedapi=1.0&embedid=9536f7d1-532a-4bf6-bcd3-caf3ec74ad01&wcp=8636404&events=interaction`;

const GiftFinderPage = () => {
  const [urlParams, setUrlParams] = useState();

  useEffect(() => {
    setUrlParams(window.document.location);
  }, []);

  //HACK Safari. Reload Iframe if stuck in Black screen
  useEffect(() => {
    if (isSafariBrowser() || isIOSBrowser()) {
      window.addEventListener("pageshow", function (event) {
        var historyPage =
          event.persisted ||
          (typeof window.performance != "undefined" &&
            window.performance.navigation.type === 2);
        if (historyPage) {
          // Handle page restore.
          window.location.reload();
        }
      });
    }
  }, []);

  return (
    <Layout>
      <MainText className="layout__maintext_desktop">
        <span>Your personal shopper</span>
      </MainText>
      <MainText className="layout__mainsubtext_desktop">
        <span>
          Find the perfect gift with Tia Mowry in this interactive shopping
          experience.
        </span>
      </MainText>
      <EkoPlayer src={EKO_PLAYER_URL} urlParams={urlParams} />
      <div className="layout__rotatelogo__wrapper">
        <img
          className="layout__rotatelogo"
          src={RotateLogo}
          alt="Rotate device"
        />
      </div>
      <MainText className="layout__maintext_mobile">
        <span>Your personal shopper</span>
      </MainText>
      <MainText className="layout__mainsubtext_mobile">
        <span>
          Find the perfect gift with Tia Mowry in this interactive shopping
          experience.
        </span>
      </MainText>
      <div className="layout__ekologo__wrapper">
        <ShopNowAd ekoId={PRODUCT_FINDER_PROJECT_ID} />
      </div>
    </Layout>
  );
};

export default GiftFinderPage;
