import React, { useEffect } from "react";

import "./eko-player.css";

const DEFAULT_EKO_URL = "/embed/V1W65G/";
const EkoPlayer = ({ src, urlParams }) => {
  useEffect(() => {
    if (!(src && urlParams)) return;

    const handleIframeEvent = (event) => {
      if (event.data && event.data.type !== "url.open") return;

      window.open(event.data.params.url, "_self");
    };
    window.addEventListener("message", handleIframeEvent, false);

    return () => {
      window.removeEventListener("message", handleIframeEvent, false);
    };
  }, [src, urlParams]);

  if (!(src && urlParams)) return "";

  // Add the parameters from the URL -> https://app.asana.com/0/1188561139409474/1196908984160529
  let iframeSrc = (src || DEFAULT_EKO_URL) + urlParams.search;
  console.log("EkoPlayer SRC=", iframeSrc);

  return (
    <div className="eko-video-container">
      <div className="eko-video-container--center">
        <iframe
          title="Gift finder"
          className="eko-video-player"
          type="text/html"
          src={iframeSrc}
          width="100%"
          height="100%"
          frameBorder="0"
          allow="fullscreen"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default EkoPlayer;
